.hidden {
  opacity: 0;
  pointer-events: none;
}

body {
  color: #fff;
  background: #000;
  margin: 0;
  font-family: Roboto Slab;
}

.inner {
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin: 0 30px;
}

.intro {
  flex-direction: column;
  align-items: center;
  transition: opacity .2s;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.intro .header {
  background: #307c4c;
  justify-content: center;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 80px;
  font-size: 32px;
  font-weight: bold;
  display: flex;
}

.intro input.id {
  box-sizing: border-box;
  color: #fff;
  background: #2c2c2c;
  border: none;
  border-radius: 10px;
  width: calc(100% - 60px);
  max-width: 600px;
  margin: 0 30px;
  padding: 15px;
  font-family: Roboto Slab;
  font-size: 22px;
  transition: all .2s;
  transform: translateY(-50%);
}

.intro input.id:focus {
  border-radius: 0;
  outline: none;
  width: 100%;
  margin: 0;
  padding: 24px 30px;
}

.intro .error {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  max-width: 660px;
  margin: 30px 0;
  position: relative;
}

.intro .error.show div {
  top: -50px;
}

.intro .error div {
  z-index: -1;
  background: #df3232;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0 30px;
  font-size: 18px;
  transition: top .1s;
  display: flex;
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
}

.intro .footer {
  color: #d9d9d9;
  cursor: pointer;
  background: #000;
  border-top: 2px solid #2c2c2c;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
  transition: background .2s;
  display: flex;
}

.intro .footer #id-text {
  color: #fff;
  font-size: 18px;
}

.intro .footer:hover {
  background: #060606;
}

.messages {
  z-index: 1;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  transition: opacity .2s;
  display: flex;
  position: fixed;
  inset: 0;
}

.messages .navbar {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-bottom: 2px solid #2c2c2c;
  align-items: center;
  padding: 20px;
  font-size: 20px;
  display: flex;
}

.messages .icon {
  background: none;
  width: 24px;
  height: 24px;
  padding: 10px;
}

.messages .back {
  cursor: pointer;
  background-image: url("back.b6cef65c.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  border-radius: 24px;
}

.messages .back:hover {
  background-color: #2c2c2c;
}

.messages .send {
  cursor: pointer;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-image: url("send.05dd1096.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  border-radius: 24px;
}

.messages .send:hover {
  background-color: #2c2c2c;
}

.messages .message-cont {
  flex-direction: column;
  gap: 5px;
  padding: 20px 20px 90px;
  display: flex;
  overflow: auto;
}

.messages .message-cont .message {
  word-wrap: break-word;
  background: #2c2c2c;
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 80%;
  padding: 10px 15px;
  font-size: 18px;
}

.messages .message-cont .message.self {
  text-align: left;
  background: #307c4c;
  align-self: flex-end;
}

.messages .input {
  background: none;
  align-items: center;
  gap: 10px;
  height: 90px;
  padding: 0 20px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.messages .input input {
  box-sizing: border-box;
  color: #000;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: #fff6;
  border: none;
  border-radius: 10px;
  outline: none;
  flex: 1;
  padding: 15px;
  font-family: Roboto Slab;
  font-size: 18px;
  transition: background .1s;
}

.messages .input input:focus {
  color: #fff;
  background: #fff3;
  outline: 2px solid #307c4c;
}

.fill {
  flex: 1;
}

/*# sourceMappingURL=index.f8eae3c5.css.map */

@error: rgb(223, 50, 50);
@green: #307c4c;
@darkgray: rgb(44, 44, 44);
@maxwidth: 600px;
@padding: 30px;
@radius: 10px;
@error-height: 50px;

.hidden {
	// display: none !important;
	opacity: 0;
	pointer-events: none;
}

body {
	margin: 0;
	font-family: "Roboto Slab";
	background: black;
	color: white;
}

& .inner {
	max-width: @maxwidth;
	width: 100%;
	margin: 0 @padding;
	box-sizing: border-box;
}

.intro {
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: fixed;
	inset: 0;
	transition: opacity ease 0.2s;

	& .header {
		display: flex;
		justify-content: center;
		width: 100%;
		padding-top: 150px;
		padding-bottom: 80px;
		background: @green;
		font-size: 32px;
		font-weight: bold;
	}

	input.id {
		max-width: @maxwidth;
		width: calc(100% - @padding * 2);
		padding: calc(@padding / 2);
		font-size: 22px;
		transform: translateY(-50%);
		border: none;
		background: @darkgray;
		font-family: "Roboto Slab";
		box-sizing: border-box;
		border-radius: @radius;
		margin: 0 @padding;
		transition: all 0.2s ease;
		color: white;

		&:focus {
			width: 100%;
			margin: 0;
			padding: calc(@padding * 0.8) @padding;
			border-radius: 0;
			outline: none;
		}
	}

	& .error {
		width: 100%;
		max-width: calc(@maxwidth + @padding * 2);
		box-sizing: border-box;
		position: relative;
		margin: @padding 0;
		user-select: none;
		pointer-events: none;

		&.show div {
			top: -@error-height;
		}

		& div {
			display: flex;
			align-items: center;
			justify-content: center;
			height: @error-height;
			margin: 0 @padding;
			position: absolute;
			left: 0;
			right: 0;
			top: calc(@error-height * 4);
			background: rgb(223, 50, 50);
			border-radius: @radius;
			font-size: 18px;
			z-index: -1;
			transition: top 0.1s ease;
		}
	}

	& .footer {
		display: flex;
		justify-content: center;
		width: 100%;
		color: darken(white, 15%);
		border-top: 2px solid @darkgray;
		padding: 20px 0;
		transition: 0.2s ease background;
		cursor: pointer;
		background: black;

		& #id-text {
			font-size: 18px;
			color: white;
		}

		&:hover {
			background: darken(@darkgray, 15%);
		}
	}
}

.messages {
	max-width: @maxwidth;
	margin: auto;
	display: flex;
	flex-direction: column;
	position: fixed;
	inset: 0;
	transition: opacity ease 0.2s;
	z-index: 1;

	& .navbar {
		padding: calc(@padding - 10px);
		display: flex;
		align-items: center;
		text-align: center;
		font-size: 20px;
		border-bottom: 2px solid @darkgray;
		user-select: none;
	}

	& .icon {
		width: 24px;
		height: 24px;
		padding: 10px;
		background: transparent;
	}

	& .back {
		background-image: url(../img/back.png?as=webp&width=24);
		background-size: 24px;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 24px;
		cursor: pointer;

		&:hover {
			background-color: @darkgray;
		}
	}

	& .send {
		background-image: url(../img/send.png?as=webp&width=24);
		background-size: 24px;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 24px;
		cursor: pointer;
		backdrop-filter: blur(5px);

		&:hover {
			background-color: @darkgray;
		}
	}

	& .message-cont {
		padding: calc(@padding - 10px);
		display: flex;
		flex-direction: column;
		gap: 5px;
		overflow: auto;
		padding-bottom: 90px;

		& .message {
			width: fit-content;
			max-width: 80%;
			background: @darkgray;
			padding: calc(@padding / 3) calc(@padding / 2);
			border-radius: @radius;
			font-size: 18px;
			word-wrap: break-word;

			&.self {
				background: @green;
				align-self: flex-end;
				text-align: left;
			}
		}
	}

	& .input {
		display: flex;
		gap: calc(@padding - 20px);
		align-items: center;
		padding: 0 calc(@padding - 10px);
		background: transparent;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 90px;

		& input {
			border: none;
			padding: calc(@padding / 2);
			font-size: 18px;
			background: fade(white, 40%);
			border-radius: @radius;
			flex: 1;
			box-sizing: border-box;
			outline: none;
			color: black;
			font-family: "Roboto Slab";
			backdrop-filter: blur(5px);
			transition: background 0.1s ease;

			&:focus {
				background: fade(white, 20%);
				color: white;
				outline: 2px solid @green;
			}
		}
	}
}

.fill {
	flex: 1;
}
